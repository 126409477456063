import Carousel from 'react-bootstrap/Carousel';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import React from 'react';

const HomeCorousel = ({ sectionData }) => {
    const optionsMainStyle = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const asset = ContentfulAssets(node?.data?.target?.sys?.id);
            if (asset) {
            return (
                <img
                src={asset?.node?.file?.url}
                alt={asset?.node?.title}
                />
            );
            }
        }
    },
    renderText: (text) =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
return (
    <Carousel>
        {sectionData?.list?.map((item,index)=>{
            return(
                <Carousel.Item key={index} interval={3000}>
                    {documentToReactComponents(
                    JSON.parse(item?.contentDetails?.raw),
                    optionsMainStyle
                    )}
                </Carousel.Item>
            );
        })}
    </Carousel>
);
};


export default HomeCorousel;